const pagos = [
    {
      title: "Fecha de pago",
      description: "04-03-2024",
      icon: `clock`,
      icon2: `calendar-month`,
    },
    {
      title: "Fecha de Vencimiento",
      description: "04-04-2024",
      icon: `clock`,
      icon2: `calendar-month`,
    },
    {
      title: "Fecha de pago",
      description: "04-03-2024",
      icon: `clock`,
      icon2: `calendar-month`,
    },
  ];
  
  export { pagos };