const Ajust = [
  {
    title: "Editar Perfil",
    icon: `PersonIcon`,
    icon2: `calendar-month`,
  },
  {
    title: "Actualizaciones",
    icon: `clock`,
    icon2: `calendar-month`,
  },
  {
    title: "Cerrar Sesion",
    icon: `clock`,
    icon2: `calendar-month`,
  },
];

export { Ajust };
